.portSection {
    padding-top: 81px;
    padding-bottom: 50px;
}

.portTitle {
    margin-bottom: 25px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #25495C;
}

.portDescr {
    max-width: 945px;
    margin:0 auto 55px;
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #607483;
}

.portCardBlock {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 85px;
}

.portLBlock, .portRBlock {
    margin-bottom: 35px;
    min-height: 640px;
    border-radius: 10px;
}

.portLBlock {
    display: flex;
    padding: 30px 38px 10px;
    align-items: center;
    justify-content: center;
    background-color: #2F80ED;
}

.portLBlock img {
    max-width: 100%;
    min-height: 100%;
}

.portRBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E50A0B;
}
.portRBlock img {
    max-width: 100%;
}
.portLeft, .portRight {
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
}

.portImgDescr {
    margin: 0 auto;
    max-width: 340px;
    text-align: center;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #607483;
}

.portBtnBlock {
    display: flex;
    justify-content: center;
}



.portBtn {
    height: 68px;

    padding: 28px 100px;
}

@media (max-width:992px) {
    .portTitle {
     
        font-size: 35px;
    }
    .portDescr {
        max-width: 645px;
        margin:0 auto 55px;
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #607483;
    }
    .portLBlock, .portRBlock {
        margin-bottom: 25px;
        min-height: 450px;
        border-radius: 10px;
    }
    
    .portImgDescr {
        max-width: 260px;
        font-size: 15px;

    }

    .portCardBlock {
        margin-bottom: 60px;
    }
}

@media (max-width:768px) {
    .portTitle {
        font-size:25px;
        margin-bottom: 35px;
    }
    .portCardBlock {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 55px;
    }
    .portLBlock, .portRBlock {
        margin-bottom: 25px;
        min-height: 300px;
        border-radius: 10px;
    }
    .portRBlock {
        padding: 60px 0  30px;
    }
}

@media (max-width:576px) {
    .portTitle {
        font-size:16px;
        margin-bottom: 25px;
    }
    .portLBlock, .portRBlock {
        margin-bottom: 25px;
        min-height: auto;
        border-radius: 10px;
    }
    .portLBlock {
        padding: 16px 12px 0px;
    }
    .portRBlock {
        padding: 50px 0 37px;
    }
    .portBtn {
        height: 55px;
    
        padding: 18px 100px;
    }
    .portDescr {
        max-width: 350px;
        margin:0 auto 45px;
        font-size: 13px;
    }
    
.portLBlock img {
    max-width: 100%;
    max-height: 500px;
}
}