.phoneHand {
    margin-left: 100px;
    background-image: url('./../assets/img/mobile-hand.png');
    max-width: 400px;
    min-height: 588px;
}
.photoCRM {
    margin-top: 50px;
    min-height: 340px;
    max-width: 600px;
    width: 100%;
    background-image: url('../assets/img/2.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.photoSEO {
    margin-top: 30px;
    margin-left: 30px;
    min-height: 500px;
    background-image: url('../assets/img/darts.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.marketPlace {
    margin-left: 90px;
    min-height: 495px;
    background-image: url('../assets/img/marketplace-img.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.grid {
    grid-template-columns: repeat(2, 1fr);
}
.gridM {

}

@media (max-width: 1200px) {
    .photoSEO {
        margin-left: 0px;
        background-image: url('./../assets/img/darts.png');
        min-height: 500px;
    }
    .gridM {
        grid-template-columns: 4fr 3fr;
    }
    .phoneHand {
        margin-left: 50px;
        background-image: url('./../assets/img/mobile-hand.png');
        max-width: 350px;
        min-height: 500px;
        
    }
    
.marketPlace {
    margin-left: 0px;
    min-height: 495px;
    background-image: url('../assets/img/marketplace-img.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
}
} 

@media (max-width: 992px) {
    .grid {
        grid-template-columns: 6fr 7fr;
    }
    .gridM {
        grid-template-columns: 3fr 2fr;
    }
    .photoSEO {
        margin-top: 50px;
        margin-left: 0px;
        background-image: url('./../assets/img/darts.png');
        min-height: 400px;
        height: auto;
    }
    .photoCRM {
        margin: 50px auto;
        min-height: 300px;
        max-width: 500px;
        width: 100%;
        background-image: url('../assets/img/2.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top;
    }
    .phoneHand {
        margin: 0 auto;
        background-image: url('./../assets/img/mobile-hand.png');
        max-width: 300px;
        min-height: 500px;
        
    }
}

@media (max-width:768px) {
    .phoneHand {
        margin: 40px auto 0;
        background-image: url('./../assets/img/mobile-hand.png');
        max-width: 300px;
        min-height: 500px;   
    }
    .marketPlace {
        margin-top: 50px;
        margin-bottom: 30px;
        min-height: 350px;
        background-image: url('../assets/img/marketplace-img.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top;
    }
}

@media (max-width:576px) {
    .photoSEO {
        margin-top: 50px;
        margin-left: 0px;
        background-image: url('./../assets/img/darts.png');
        min-height: 290px;
    }
    .marketPlace {
        margin-top: 50px;
        margin-bottom: 0px;
        min-height: 350px;
        background-image: url('../assets/img/marketplace-img.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top;
    }
    .photoCRM {
        margin-top: 60px;
        min-height: 166px;
        max-height: 500px;
        height: 100%;
        max-width: 100%;
        width: auto;
        background-image: url('../assets/img/2.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top ;
    }
    .phoneHand {
        margin: 40px auto 0;
        background-image: url('./../assets/img/mobile-hand.png');
        max-width: 300px;
        min-height: 350px;
    }
}