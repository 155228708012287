.offer {
    margin: 120px 0;
}

@media (max-width:768px) {
    .offer {
        margin: 70px 0;
    }
}

@media (max-width:576px) {
    .offer {
        margin: 50px 0 10px;
    }
}