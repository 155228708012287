.keysMain {
    padding-top: 35px;
}
.cont {
    display: flex;
    justify-content: end;
}

.reddit {
    font-family: 'GothamProLight';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: -0.02em;

    color: #F84263;
}

.keysCont {
    max-width: 1040px ;
    width: 100%;
}

.pag {
    display: flex;
    align-items: center;

    margin-top: 25px;
}
.gray {
    margin-top: 2px;
    font-family: 'GothamProLight';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: -0.02em;
    color: #ACB8C0;
}
.pagPoint {
    margin-right: 7px;
    margin-left: 7px;
    border-radius: 50%;
    width: 3px;
    height: 3px;
    background: #DADBDD;
    display: inline-block;
  
}


.keysNavList {
    padding: 40px 0 45px 4px;
    grid-template-columns: repeat(8, auto);
}

.keysSwipeItem {
    filter: drop-shadow(0px 14px 24px rgba(207, 201, 201, 0.25));
}

.keysNavItem {
    padding: 17px 0;
    outline: 1px solid #DBE0E3;
}

.disable {
    opacity: 0;
    display: none;
}

.active {
    animation: activeI alternate .35s ease-in-out forwards;
}

@keyframes activeI {
    0% {
        display: block;
        opacity: 0;
    }
    100% {
        opacity: 1;
        display: block;
    }
}

@keyframes disableI {
    0% {
        opacity: 1;
        display: block;
    }
    100% {
        opacity: 0;
        display: none;
    }
}


@media (max-width:768px) {

    .keysNavList {
        padding: 30px 0 35px;
    }
}

@media (max-width:576px) {
    .keysMain {
        padding-top: 20px;
    }
    .keysNavList {
        padding: 20px 0 32px;
    }
    .pag {
        margin-top: 10px;
    }
}