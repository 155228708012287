
.reviewsSection {
    margin-top: 45px;
    margin-bottom: 43px;
}

.reviewsTitle {
    margin-bottom: 95px;
    max-width: 946px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #25495C;
}

.reviewsListBlock {
    width: 100%;
}
.container {
    display: flex;
    justify-content: center;
}
.reviewsCont {
    max-width: 950px;
}

.reviewsList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
}

.reviewsItem {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 30px;
    border: 1px solid #E9ECEE;
    border-radius: 10px;
    width: 100%;
    padding: 65px 95px 65px 55px;
}

.reviewsImg {
    border-radius: 50%;
    margin-bottom: 33px;
}

.reviewsTitleItem {
    margin-bottom: 20px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    /* or 19px */

    letter-spacing: -0.02em;

    color: #000000;
}


.reviewsDescrItem {
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    letter-spacing: -0.04em;
    color: #ACB8C0;
    max-width: 165px;
}

.reviewsDescr {
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    letter-spacing: -0.02em;

    color: #607483;
}


@media (max-width:992px) {
    .reviewsDescr {
        font-size: 15px;
    }
}

@media (max-width:768px) {
    .reviewsTitle {
        margin: 0 auto 60px;
        max-width: 946px;
    }
    .reviewsList {
        gap: 20px;
    }
    .reviewsItem {
        gap: 15px;
        padding: 40px 70px 40px 40px;
    }
    .reviewsDescr {
        font-size: 13px;
    }
}

@media (max-width: 576px) {
    .reviewsTitle {
        margin: 0 auto 20px;
    }
    .reviewsItem {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        gap: 40px;
        padding: 25px 21px 35px 25px;
    }
    .reviewsRight {
        order: -1;
    }
    .reviewsImgCard {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 6fr;
        gap: 30px;
    }
    .reviewsImgBlock {
        display: inline-block;
        max-width: 60px;
    }
    .reviewsImg {
        margin: 0;
        max-width: 60px;
        height: 60px;
    }

    .reviewsTitleItem {
        display: flex;
        align-items: center;
        margin: 0;
        max-width: 60px;
    }
}