.title {
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 46px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #25495C;
} 
@media (max-width:768px) {
    .title {
        font-size: 35px;
    }
}
@media (max-width:576px) {
    .title {
        font-size: 20px;
    }
}