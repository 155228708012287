
.mail {
    color: var(--pink-color);
    text-decoration: none;
    transform: translateY(-1px);
}



.header {
    position: sticky;
    top: 0px;
    background-color:#fff; 
    width: 100%;
    z-index: 20;
    overflow:hidden;
    transition: all .35s ease-out;
    box-shadow: 0px 4px 14px rgba(245, 238, 238, 0.25);
    transform: translateY(-80px);
    opacity: 0;
}

.headerL {
    opacity: 1;
    transform: none;
}

.headerCont {
    transition: all .35s ease-in-out;
}

.headerContacts  {
    display: flex;
    justify-content: center;
    align-items: center;
}


.headerActive {
    top: -45px;
}
.topCard {
    width: 100%;
    box-shadow: 0px 4px 14px rgba(245, 238, 238, 0.25);

}
.topHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0;
    align-items: center;
    transition: all .35s ease-in-out;
}

.changeitem {
    width: 100%;
    display: inline-block;
    justify-self: center;
    position: relative;
}

.fixLinks {
    display: flex;
}

.fix {
    position: absolute;
    top: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 10px;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all .35s ease-in-out;

}

.nav {
    display: inline-grid;
    grid-template-columns: repeat(4, auto);
    gap: 21px;
    align-content:center;
    transition: all .35s ease-in-out;
    pointer-events: all;
}

.nav a {
    display: flex;
    align-items: center;
}

.mHeaderDropItem {
    display: none;
}

.topHeaderActive .changeitem .nav {
    pointer-events: none;
    opacity: 0;
}


.topHeaderActive .changeitem .fix {
    pointer-events: all;
    opacity: 1;
}


.topList {
    display: flex;
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: -0.02em;
}

.mobileNav {
    display: none;
}
.textUs_span {
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    text-align: right;
    letter-spacing: -0.02em;
    color: #ACB8C0;
    margin-left: 40px;

}

.navBlock {
    display: inline-flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.navArrow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
}

.navArrow svg {
 transition: all .35s ease-in-out;
}

.navArrowActive .nav_span  {
    color: var(--pink-color) !important;
}



.navArrowActive .navArrow svg {
    transform: rotate(180deg);
   fill: var(--pink-color);
}



.textUs_spanF {
    margin-left: 0;
    padding-top: 2px;
}

.links{
    margin-left: 17px;
    text-decoration: none;
    color:#ACB8C0;
    cursor: pointer;
    transition: color .35s ease;
}

.links:hover {
    color: var(--grey-color);
}

.topLinks {
    display: flex;
}
.textUs {
    display:flex;
    flex-direction: row;
    align-items: center;

}

.iconBlock {
    display: flex;
    position: relative;
}

.icon {
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 10px;
    transition: all .35s ease-in-out;
    box-sizing: border-box;
}

.whatsapp {
    width: 17px;
    height: 17px;
    transition: all .35s ease-in-out;
}

.tg {
   
    width: 19px;
    height: 16px;
}


.whatsapp svg,  .tg svg  {
    transition: all .35s ease-in-out;
}

.whatsapp:hover svg, .tg:hover svg {
    fill: #4C6171;
}


.fLink {
    margin-left: 0;
}



.bottomHeader{
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 15px;
}
.company {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}



.achorMain {
    text-decoration: none;
}

.name {
    text-decoration: none;
    margin-left: 10px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    letter-spacing: -0.04em;
    margin-bottom: 3px;
    color: #25495C;
}
.whoWe {
    margin-left: 10px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height, or 120% */

    letter-spacing: -0.02em;
    text-transform: uppercase;

    color: #ACB8C0;
}
.nav_span {
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #4C6171
}


.numberBtnBlock {
    display: flex;
    justify-content: center;
    align-items: center;
}


.burgerBlock {
    display: none;
}
.number {
    margin-right: 27px;
    text-decoration: none;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 21px;
    /* identical to box height */

    text-align: right;
    letter-spacing: -0.02em;

    color: #25495C;
}
.btn{
  padding: 17px 24px 17px 23px;
}

.modalTitle {
    font-family: 'GothamProMedium';
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--deep-blue);
}



.modalDescr {
    display: inline-block;
    padding:  0 10px;
    max-width: 406px;
    margin-bottom: 32px;
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #607483;
    opacity: 0.8;
}

.modalWarning {
    display: inline-block;
    margin-bottom: 20px;
    max-width: 406px;
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--rock-grey-color);
    opacity: 0.7;
}

.modalBtn {
    padding: 28px 163px;
}

.modalInput {
    margin-bottom: 10px;
    width: 100%;
    height: 68px;
    padding: 26px 35px 19px !important;
}



.modalMask {
    margin-bottom: 32px;
    width: 100%;
    padding: 24px 36px;
}

.modalMask::placeholder {
    color: var(--rock-grey-color);
    font-size: 14px;
}

.logo {
    display: inline-block;
    width: 41px;
    height: 44px;
    background-image: url('../assets/img/logo-header.svg');
    background-repeat: no-repeat;
}


.navBlockMain {
    position: fixed;
    max-width: 1140px;
    width: 100%;

    height: 80vh;
    max-height: 655px;

    top: 134px;
    transition: all .35s ease-in-out;
    transform: translateX(-120vw);

    box-shadow: 0px 14px 24px rgba(172, 166, 166, 0.25);


    opacity: 1;
    overflow-y: auto;
    border-radius: 3px;
}

.navBlockMain::-webkit-scrollbar {
    display: none;
}

.navBlockMainActive {
    opacity: 1;
   
    transform: translateY(0);
}

.headerDropMainList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;


    padding: 55px 0 55px 63px;

    max-width: 1140px;
    background-color: #fff;

    border-radius: 3px;
    z-index: inherit;
   
}




.headerICtitle {
    margin-bottom: 20px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #000000;
    transition: all .3s ease;
    cursor: pointer;
}

.headerICtitle:hover {
    color: var(--pink-color);
}

.headerIClist {
    display: grid;
    gap: 6px;
}
.headerTopCI {
    margin-top: 40px;
}

.headerICitem  a {
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    letter-spacing: -0.02em;

    color: #969494;
    transition: all .35s ease;
    cursor: pointer;
}

.headerICitem a:hover {
    color: var(--pink-color);
}



@media (max-width: 1200px) {
    .number {
        margin-right: 15px;
        text-decoration: none;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: right;
        letter-spacing: -0.02em;
        color: #25495C;
    }

    .navBlockMain {
        position: fixed;
        width: 96vw;
        top: 141px;
        height: 75vh;
        transition: all .5s ease-in-out;
        transform: translateX(-100vw);
        opacity: .5;
        overflow-y: auto;
    }
    .navBlockMainActive {
        opacity: 1;
        transform: translateX(0);
    }
    
    
}

@media (max-width:992px) {

    .textUs_spanF {
        margin-left: 0;
    }
 
    .navArrow svg {
        width: 6px;
    }

     .links {
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        letter-spacing: -0.02em;
    }
 

    .mail {
        font-size: 12px !important;
        width: 118px;
        transform: none;
    }

    .textUs_span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        padding-top: 1px;
    }
    .navBlockMain {
        height: 80vh;
    }

    .navPage {
        text-decoration: none;
    }

    .iconBlock a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }

    .whatsapp, .tg {
        width: 15px;
        height:15px;
    }
    .whatsapp svg {
        width: 100%;
        height: 100%;
    }

    .bottomHeader {
        gap: 15px;
    }


    .tg svg {
       
        width: 17px;
        height: 15px;
    }

  
    .logo {
        display: inline-block;

        width: 30px;
        height: 30px;
        background-image: url('../assets/img/logo-header.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }


    .name {
        margin-left: 3px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -0.04em;
        margin-bottom: 0;
        color: #25495C;
        
    }
    .whoWe {
        margin-left: 3px;
        font-weight: 500;
        font-size: 11px;
        text-transform: lowercase;
    }

    .nav_span {
        display: flex;
        justify-content: center;
        align-items: center;
      
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px !important;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #4C6171
    }

    .name, .whoWe { 
        line-height: 1;
    }

    .number {
        font-size: 13px;
        width: 126px;
        margin-right: 0;
      
    }

    .btn {
        padding: 11px 13px;
        margin-left: 15px;
        font-size: 12px !important;
    }

    .navBlockMain {
        top: 130px;
        width: 95vw;
        height: 75vh;
        transition: all .5s ease-in-out;
        transform: translateX(-100vw);
        opacity: .5;
    }
    .navBlockMainActive {
        opacity: 1;
        transform: translateX(0);
    }

    .headerDropMainList {
        padding: 56px 43px 60px 43px;
        grid-template-columns: repeat(3, 1fr);
    }


    .headerICtitle {
        margin-bottom: 15px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #000000;
        transition: all .3s ease;
        cursor: pointer;
    }

    
    .headerICitem {
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
      

        letter-spacing: -0.02em;

        color: #969494;
        transition: all .3s ease;
        cursor: pointer;
    } 

    .icon {
        margin-left: 5px;
    }


    .numberBtnBlock {
        justify-content: end;
    }

    .nav {
        gap: 15px;
    }

    .headerActive {
        top: -43px;
    }

    .modalInput {
        margin-bottom: 10px;
        width: 100%;
        max-width: 400px;
        height: 68px;
        padding: 26px 35px 19px !important;
    }
    
    .modalMask {
        margin-bottom: 32px;
        width: 100%;
        max-width: 400px;
        padding: 24px 36px;
    }

    .modalPlace {
        left: 85px !important;
    }

    
    
}

@media (max-width: 768px) {


    .logo {
        display: inline-block;

        width: 30px;
        height: 30px;
        background-image: url('../assets/img/logo-header.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .navBlockMain {
        transition: all .35s ease-in;
        width: 94vw;
        height: 80vh;
        top: 124px;
    }

    .topHeaderActive .navBlockMain {
        top: 80px;
        height: 90vh;
    }

    .mHeaderDropItem {
        display: block;
        transform: translateY(-180px);
    }
    .changeitem {
        display: none;
    }
    .header {
        position: sticky;
        top: 0;
        background-color:#fff; 
        width: 100%;
        z-index: 20;
        overflow:hidden;
        transition: all .35s ease-in-out;
    }

    .headerCont {
        transition: all .35s ease-in-out;
    }
    
    .headerActive {
        top: -38px;
        box-shadow: 0px 4px 14px rgba(245, 238, 238, 0.25);
    }

    .topHeader {
        margin: 0;
    }

    
    .topHeader {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        align-items: center;
        transition: all .4s ease-in-out;
        box-shadow: 0px 4px 14px rgba(245, 238, 238, 0.25);

    }

    .bottomHeader {
        padding-top: 15px;
        padding-bottom: 15px;
        grid-template-columns: repeat(3, auto);
    }

    .numberBtnBlock {
        justify-content: end;
    }

    .burgerBlock {
        display: inline-block;
        justify-self: end;
        align-self: center;
        position: relative;
        width: 21px;
        height: 8px;
        cursor: pointer;
    }

    
    .burgerLine {
        position: absolute;
        top: 0;
        width: 100%;
        height: 2px;
        background-color: var(--pink-color);
        border-radius: 5px;
        transition: all .35s ease;
    }

    .burgerLine::before {
        content: '';
        position: absolute;
        top: 6px;
        right: 0;
        height: 2px;
        width: 14px;
        background-color: var(--pink-color);
        border-radius: 5px;
        transition: all .35s ease;
    }

    .burgerActive .burgerLine {
        transform:  translateY(4px) rotate(-45deg);
    }

    .burgerActive .burgerLine::before {
        width: 20.73px;
        transform: translateY(-6px)  rotate(90deg);
    }

    
    .topList {
        display: flex;
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        letter-spacing: -0.02em;
    }

    .links {
        margin-left: 10px;
    }

    .textUs_span {
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        text-align: right;
        letter-spacing: -0.02em;
        color: #ACB8C0;
        margin-left: 20px;

    }

    .icon {
        display: inline-block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 5px;
        transition: all .4s ease-in-out;
        box-sizing: border-box;
    }

    .name {
        margin-left: 3px;
        margin-bottom: 0;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        letter-spacing: -0.04em;
        margin-bottom: 0px;
        color: #25495C;
    }

    .nav_span {
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 15px;
        letter-spacing: -0.02em;
        margin-left: 8px;
    }    

    .number {
        margin: 0;
        text-decoration: none;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        width: 120px;
        line-height: 21px;
        /* identical to box height */
    
        text-align: right;
        letter-spacing: -0.02em;
    
        color: #25495C;
    }

    


    .headerDropMainList  {
        grid-template-columns: repeat(2, 1fr);
        width: 95vw;
        overflow-y: auto;
    }

    .headerDropMainList::-webkit-scrollbar {
        width: 0px;
    }

    .whoWe {
        margin-left: 3px;
     
    }



}
  
   
@media (max-width:576px) {

    .mHeaderDropItem {
        display: block;
        transform: translateY(0px);
    }

    
    .navBlockMain {
        transition: all .3s ease-in;
        width: 100vw;
        max-height: 95vh;
        height: 95vh;
        transition: all .5s ease-in-out;
        transform: translateX(-110vw);
        opacity: 1;
        top: 47px;
    }

    .numberBtnBlock {
        justify-self: end;
    }

    .topHeaderActive .navBlockMain {
        top: 47px;
        height: 100vh;
        
    }

    .header {
        position: sticky;
        top: 0;
        background-color:#fff; 
        width: 100%;
        z-index: 20;
        overflow:hidden;
        padding: 12px 0 12px;
        transition: all .35s ease-in-out;
    }
    
    .headerCont {
        transition: all .35s ease-in-out;
    }
    
    .headerActive {
        top: 0;
        box-shadow: 0px 4px 14px rgba(245, 238, 238, 0.25);
    }
    

    .topHeader {
        display: none;
    }
    .headerCont {
        padding: 0 !important;
    }

    .logoWrap {
        padding: 0;
    }

    .bottomHeader {
        display: grid;
        padding: 0;
        grid-template-columns: 5fr 5fr auto;
        height: 100%;
        gap: 5px;
    }

    .numberWrap {
        display: flex;
        justify-content: end;
        padding-right: 18px;
    }

    .number {
        display: inline-block;
        margin-left: auto;
        margin-right: 0;
        margin: 0;
    }

    .whoWe {
        display: none;
    }

    .nav {
        display: none;
    }

    .btn {
        display: none;
    }




    .number {
        font-size: 12px;
    line-height: 11px;
    }

    .logo {
        display: inline-block;
        width: 21px;
        height: 21px;
        background-image: url('../assets/img/logo-header.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .name {
        margin-bottom: 0;
        margin-left:5px;
        font-size: 12px;
        line-height: 10px;
        width: 86px;
    }

    .headerDropMainList {
        padding: 41px 15px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        width: 100%;
        height: auto;
    }

    .navBlockMainActive {
        opacity: 1;
        transform: translateX(-15px);


    }
    


    
    .headerICtitle {
        margin-bottom: 18px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #000000;
        transition: all .3s ease;
        cursor: pointer;
    }
    
    .headerICtitle:hover {
        color: var(--pink-color);
    }
    
    .headerIClist {
        display: grid;
        gap: 7px;
    }
    .headerTopCI {
        margin-top: 43px;
    }
    
    .headerICitem {
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
line-height: 130%;
        /* or 18px */
    
        letter-spacing: -0.02em;
    
        color: #969494;
        transition: all .3s ease;
        cursor: pointer;
    }
    
    .headerICitem:hover {
        color: var(--pink-color);
    }
    .activeNav {
        color: var(--pink-color) !important; 
    }
}
