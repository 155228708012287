html,
body {
    position: relative;
    height: 100%;
}

body {
    font-family: GothamProLight;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper {
    width: 100%;
    height: auto !important;

}



.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    display: none;
}
.swiper-button-next {
    margin-right: 18px;
}
.swiper-button-prev {
    margin-left: 18px;
}
.swiper-button-next, .swiper-button-prev {

    width: 42px;
    height: 42px;
    color: black;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 14px 24px rgba(207, 201, 201, 0.25);

}
.swiper-wrapper {
    /*height: 100% !important;*/
    /*position: relative;*/
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 8px;
    font-weight: 700;
    box-shadow: 0px 14px 24px rgba(207, 201, 201, 0.25);
}
.swiper-slide {
    height: auto !important;
}
.swiper-slide:first-child {
    /*height: 600px !important;*/
    /*align-items: center !important;*/

}
.swiper-pagination {
    display: inline-block;
    text-align-last: left;
    margin: auto !important;
    height: 10px;
     left: 0; right: 0;
    max-width: 1170px;
    padding: 0 15px;
    bottom: 96px !important;
}





.swiper-pagination-bullet {
    height: 10px;
    width: 10px;
}
.swiper-pagination-bullet-active {
    box-sizing: border-box;
    height: 10px;
    width: 10px;
    border: 3px solid red;
    background: white;
}

@media (max-width: 1200px) {
    .swiper-pagination-horizontal {
        margin-left: 30px !important;
    }
}

@media (max-width: 992px) {
    .swiper-button-next {
        margin-right: 0;
    }
    .swiper-button-prev {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .swiper-pagination {
        display: inline-block;
        text-align-last: left;
        margin: auto !important;
        height: 10px;
         left: 30px; right: 0;
        max-width: 1160px;
        bottom: 26px !important;
    }

    .swiper-pagination-horizontal {
        margin-left: 30px !important;
    }

    .swiper-pagination-bullet {
        height:8px;
        width: 8px;
    }
}

@media (max-width:576px) {
    .swiper-button-next, .swiper-button-prev{
        display: none !important;
    }
    .swiper-pagination-horizontal {
        margin-left: 0 !important;
    }
    .swiper-pagination {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align-last: left;
        margin: auto !important;
        height: 10px;
         left: 30px; right: 0;
        max-width: 1160px;
        bottom: 23px !important;
    }

}
