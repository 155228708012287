.textareaBlock {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
   
}  

.myTextarea {
    width: 100%;
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: -0.02em;
    border-radius: 5px;
    outline: none;
    resize: none;
}

.myTextarea:valid:required ~ .textareaPlace,
.myTextarea:focus ~ .textareaPlace {
    transform: translateY(-12.5px);
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 130%;
    /* or 13px */

    letter-spacing: -0.02em;
}

.textareaPlace {
    position: absolute;
    left: 37px;
    top: 22px;
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: -0.02em;

    pointer-events: none;
    transition: all .3s ease-in-out;
}



@media (max-width:576px) {
    
}

