.lider {
    min-height: 700px;
    background-image: url('../assets/img/lider-bg.jpg');
    background-position: center;
    background-size: cover;
    overflow:hidden;
}

.liderContent {
    display: grid;
    grid-template-columns: 52% auto;
    gap: 139px;
    padding-top: 77px;
}


.liderTitle {
    margin-bottom: 58px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--deep-blue); 
}

.liderDescrBlock {
    margin-bottom: 58px;
}

.liderDescr {
    max-width: 407px;
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #607483;
}

.liderDescrB {
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: var(--rock-grey-color);
}


.liderInfo {
    max-width:431px;
    margin-bottom: 40px;
    background-color: #fff;
    padding: 19px 0 26px 35px;
    box-shadow: 0px 14px 24px rgba(207, 201, 201, 0.25);
border-radius: 10px;
}

.liderName {
    margin-bottom: 13px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    letter-spacing: -0.02em;

    color: #000000;
}

.liderNameDescr {
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 12px;
    letter-spacing: -0.02em;
    color: var(--sky-grey-color);
}

.liderQuote {
    display: inline-block;
    width: 38px;
    height: 48px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../assets/img/literals.svg');
}

.liderDescrBlock {
    display: grid;
    grid-template-columns: 6% auto;
    gap: 28px;
}

.liderRight {
    display: flex;
    align-items: end;
}

.liderPerson {
    height: 623px;
    width: 100%;
    background-image: url('../assets/img/male-bg.png');
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
}

@media (max-width:1200px) {
    .liderContent {
        display: grid;
        height: 100%;
        padding-left: 30px;
        grid-template-columns: 52% auto;
        gap: 139px;
        padding: 67px 30px 0;
    }
    
.liderPerson {
    height: 623px;
    width: 100%;
    background-image: url('../assets/img/male-bg.png');
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
}
}


@media (max-width: 992px) {
    .lider {
        height: 500px;
        background-image: url('../assets/img/lider-bg.jpg');
        background-position: center;
        background-size: cover;
        overflow:hidden;
    }
    .liderContent {
        display: grid;
        height: 100%;
        grid-template-columns: 52% auto;
        gap: 139px;
        padding: 67px 30px 0;
    }

    .liderTitle {
        margin-bottom: 38px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: var(--deep-blue); 
    }
    
    .liderDescrBlock {
        margin-bottom: 38px;
    }
    
    .liderDescr {
        max-width: 347px;
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #607483;
    }

    .liderDescrB {
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: var(--rock-grey-color);
    }
    
    
    .liderInfo {
        max-width: 331px;
        background-color: #fff;
        padding: 15px 0 27px 25px;
    }
    
    .liderName {
        margin-bottom: 13px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        /* or 23px */
    
        letter-spacing: -0.02em;
    
        color: #000000;
    }
    
    .liderNameDescr {
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: -0.02em;
        color: var(--sky-grey-color);
    }
    
    .liderQuote {
        display: inline-block;
        width: 38px;
        height: 48px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/img/literals.svg');
    }
    
    .liderDescrBlock {
        display: grid;
        grid-template-columns: 6% auto;
        gap: 28px;
    }
    
    .liderPerson {
        height: 623px;
        background-image: url('../assets/img/male-bg.png');
        background-size: contain;
        background-position: left bottom;
        background-repeat: no-repeat;
    }

    
    .liderNameDescr {
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.02em;
        color: var(--sky-grey-color);
    }

    .liderQuote {
        display: inline-block;
        width: 32px;
        height: 42px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/img/literals.svg');
    }

    .liderDescrBlock {
        display: grid;
        grid-template-columns: 6% auto;
        gap: 26px;
    }

    .liderPerson {
        height: 488px;
        background-image: url('../assets/img/male-bg.png');
        background-size: contain;
        background-position: left bottom;
        background-repeat: no-repeat;
    }

}

@media (max-width:768px) {
    .lider {
        min-height: 400px;
        background-image: url('../assets/img/lider-bg.jpg');
        background-position: center bottom;
        background-size: cover;
        overflow:hidden;
    }

   
    .liderContent {
        display: grid;
        grid-template-columns: 10fr 9fr ;
        height: 100%;
        gap: 59px;
        padding: 60px 0 0;
    }

    .liderTitle {
        margin-top: 30px;
        margin-left: 20px;
        margin-bottom: 30px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: var(--deep-blue); 
    }
    
    .liderDescrBlock {
        margin-bottom: 32px;
    }
    
    .liderDescr {
        max-width: 347px;
        height: 100%;
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #607483;
    }

    .liderDescrB {
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: var(--rock-grey-color);
    }
    
    
    .liderInfo {
        max-width: 261px;
        background-color: #fff;
        padding: 15px 0 16px 22px;
    }
    
    .liderName {
        margin-bottom: 9px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        /* or 23px */
    
        letter-spacing: -0.02em;
    
        color: #000000;
    }
    
    .liderNameDescr {
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: var(--sky-grey-color);
    }
    
    .liderQuote {
        display: inline-block;
        width: 29px !important;
        height: 29px !important;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/img/literals.svg');
    }
    
    .liderDescrBlock {
        display: grid;
        grid-template-columns: 6% auto;
        gap: 28px;
    }
    
    .liderPerson {
        height: 422px;
        background-image: url('../assets/img/male-bg.png');
        background-size: contain;
        background-position: left;
        background-repeat: no-repeat;
    }

    
    .liderNameDescr {
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: var(--sky-grey-color);
    }

    .liderQuote {
        display: inline-block;
        width: 31px;
        height: 41px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/img/literals.svg');
    }

    .liderDescrBlock {
        display: grid;
        grid-template-columns: 6% auto;
        gap: 26px;
    }

    .liderPerson {
        height: 382px;
        background-image: url('../assets/img/male-bg.png');
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
    }
}  

@media (max-width:576px) {
    .lider {
        height: 683px;
        padding-bottom: 50px;
        background-image: url('../assets/img/lider-bg.jpg');
        background-position: center;
        background-size: cover;
        overflow:hidden;
    }
    .liderContent {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto 319px;
        gap: 34px;
        padding: 67px 0 0;
    }

    .liderTitle {
        text-align: center;
        margin-bottom: 29px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: var(--deep-blue); 
    }
    
    .liderDescrBlock {
        display: inline-grid;
        margin-bottom: 32px;
    }
    
    .liderDescr {
        text-align: left;
        max-width: 347px;
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #607483;
    }

    .liderDescrB {
        display: inline-block;
        text-align: left;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: var(--rock-grey-color);
    }
    
    
    .liderInfo {
        display: inline-block;
        width: 100%;
        max-width: 300px;
        background-color: #fff;
        padding: 19px 0 13px 25px;
        box-shadow: 0px 14px 24px rgba(207, 201, 201, 0.25);
        border-radius: 10px;
    }
    
    .liderName { 
        text-align: left;
        margin-bottom: 8px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
        /* or 23px */
    
        letter-spacing: -0.02em;
    
        color: #000000;
    }

    .liderLeft {
        text-align: center;
    }
    
    .liderNameDescr {
        text-align: left;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px !important;
        max-width: 200px;
        line-height: 150% !important;
        letter-spacing: -0.02em;
        color: var(--sky-grey-color);
    }
    
    .liderQuote {
        display: inline-block;
        width: 28px !important;
        height: 28px !important;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/img/literals.svg');
    }
/*     
    .liderDescrBlock {
        display: grid;
        grid-template-columns: 6% auto;
        gap: 28px;
    } */
    
    .liderPerson {
        margin-left: 20vw;
        min-height: 319px;
        /* height: 319px; */
        background-image: url('../assets/img/male-bg.png');
        background-size: contain;
        background-position: left;
        background-repeat: no-repeat;
    }

    
    .liderNameDescr {
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.02em;
        color: var(--sky-grey-color);
    }

    .liderQuote {
        display: inline-block;
        width: 32px;
        height: 41px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../assets/img/literals.svg');
    }

}