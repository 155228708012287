.seoSection {
    padding-top: 140px;
}
@media (max-width:992px) {
    .seoSection {
        padding-top: 110px;
    }
}
@media (max-width:768px) {
    .seoSection {
        padding-top: 70px;
    }
}
@media (max-width:576px) {
    .seoSection {
        padding-top: 40px;
    }
}