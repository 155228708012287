.miniSection {
   
    position: relative;
    background: linear-gradient(260.29deg, #778D9D 18%, #6F818F 86.07%);
    mix-blend-mode: multiply;
    overflow: hidden;
    min-height: 400px;
}
.overlay {
    
    position: absolute;
    display: inline-block;
    background-image: url('./../assets/img/bc-mini-seo.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100%;
    mix-blend-mode: multiply;
    z-index: -1;
}

.cont {
    padding: 66px 0 62px;
    text-align: center;
}

.miniImg {
    display: inline-block;
    margin-bottom: 35px;
    width: 47px;
    height: 47px;
    background-image: url('./../assets/img/seo-mini.svg');
}


.miniTitle {
    margin-bottom: 70px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.miniDescr {
    display: inline-block;
    max-width: 820px;
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    opacity: 0.5;
}

@media (max-width:992px) {
    .cont  {
        padding-left:28px;
        padding-right:28px;
    }
    .miniTitle {
        margin-bottom: 50px;
    }
}

@media (max-width:768px) {
    .miniSection { 
        min-height: 370px;
    }
    .miniTitle {
        font-size: 22px;
        margin-bottom: 30px;
    }
    .miniDescr {
        font-size: 16px;
    }
}

@media (max-width:576px) {
    .cont  {
        padding: 40px 28px 50px;
    }
    .miniSection { 
        min-height: auto;
    }
    .miniTitle {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .miniDescr {
        font-size: 14px;
    }
}