.simple {
    padding: 138px 0 136px;
}

.simpleContent {
    height: 100% !important;
    text-align: center;
}

.mySwiper {
    padding: 0 15px;
    max-width: 1170px;
    width: 100%;
   
    margin: 0 auto;
}

.simpleList {
  
    display: grid ;
    grid-template-columns: repeat(3, 1fr) ;
    grid-template-rows: repeat(2, 1fr) ;
    gap: 20px ;
    margin-bottom: 30px;
}

.simpleTitle {
    margin-bottom: 63px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--deep-blue);
}

.simpleItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 360px;
    height: 100%;
    padding: 46px 0 44px;
    text-align: center;
    background: #fff;
    box-shadow: 0px 14px 24px rgba(207, 201, 201, 0.25);
    border-radius: 10px;
}
@media (min-width: 576px) {
    .pag {
        display: none;
    }
}

@media (max-width: 576px) {
   
    .pag {

        margin-top: 45px;
    }
}

.simpleImg {
    height: 44px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.simpleItemTitle {
    display: inline-block;
  
    max-width: 252px;
    height: auto;
    margin: 20px;
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
}

.simpleItemDescr {
    display: inline-block;
    max-width: 268px;
    font-family: 'GothamProRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    min-height: 115px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #607483;
}

.simpleCount {
    font-family: 'GothamProMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #F84263;
}

@media (max-width: 992px) {
    .simple {
        padding: 100px 0 100px;
    }

    .simpleContent {
        text-align: center;
    }

    .simpleTitle {
        margin-bottom: 63px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 33px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: var(--deep-blue);
    }

    .simpleList {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 21px;
    }

    .simpleImg {
        height: 34px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .simpleItem {
        min-height: 320px;
    }

    .simpleItemTitle {
        display: inline-block;
        margin-top: 35px;
        max-width: 252px;
       
        margin-bottom: 30px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }

    .simpleItemDescr {
        display: inline-block;
        max-width: 270px;
        font-family: 'GothamProRegular';
        font-style: normal;
        min-height: 80px;
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #607483;
    }

    .simpleCount {
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #F84263;
    }
}

@media (max-width: 768px) {
    .simple {
        padding: 80px 0 70px;
    }

    .simpleContent {
        text-align: center;
    }

    .simpleTitle {
        margin-bottom: 43px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: var(--deep-blue);
    }

    .simpleList {
        display: grid;
       
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 21px;
    }

    .simpleItem {
        display: flex;
        flex-direction: column;
        padding: 30px 20px 20px;
        min-height: 250px;
        text-align: center;
        background: #fff;
        box-shadow: 0px 14px 24px rgba(207, 201, 201, 0.25);
        border-radius: 10px;
    }

    .simpleImg {
        height: 24px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .simpleItemTitle {
        display: inline-block;
        margin-top: 35px;
        max-width: 252px;
      
        margin-bottom: 30px;
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }

    .simpleItemDescr {
        display: inline-block;
        max-width: 268px;
        font-family: 'GothamProRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #607483;
    }

    .simpleCount {
        font-family: 'GothamProMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #F84263;
    }
}

@media (min-width: 576px) {
    .mySwiper {
        height: 100% !important;
    }
}

@media (max-width: 576px) {

    .simple {
        padding-bottom: 30px;
    }

    .simpleList {
        
        display: flex !important;
        grid-template-columns: none !important;;
        grid-template-rows: none !important;;
        gap: 0 !important;
    }

    .simpleTitle {
        margin: 0 auto 7px;
        font-size: 16px;
        line-height: 150%;
        max-width: 290px;
    }

    .simpleItem {
        max-width: 230px;
        justify-content: space-around;
        min-height: 250px;
        height: 100%;
        margin: 0;
        padding: 26px 13px;
    }

    .swipSl {
        height: auto;
      
    }

    .swipSl:last-child {
        margin-right: 5px;
    }

    .simpleItemTitle {
        margin: 33px auto 15px;
        font-size: 14px;
        line-height: 130%;
        height: auto;
        max-width: 180px;
    }

    .swipSl {
        max-width: 240px;
        /*margin-left: 10px;*/
            width: 240px !important;
    }

    .simpleItemDescr {
        font-size: 12px;
        line-height: 150%;
        max-width: 196px;
        height: auto;
        min-height: fit-content;
    }

    .simpleCount {
        font-size: 24px;
        line-height: 110%;
    }
        
    .mySwiper {
        min-height: 250px;
    }

}
