.keysSitesBlock {
    transition: all .35s ease-in-out;
    padding-bottom: 100px;
}

.keysSitesLisst {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
    gap: 90px;
}

.orangePhone, .ecoFemale {
    display: flex;
    align-items: end;
    height: 100%;
    min-height: 400px;
}

.ecoFemale img {
    width: 310px;
    height: 320px;
}

.hockeyImg img {
    width: 356px;
    height: 274px;
}

@media (max-width:768px) {
    .orangePhone, .ecoFemale {
        display: flex;
        align-items: end;
        height: 100%;
        min-height: auto;
    }
}

@media (max-width:576px) {
    .keysSitesLisst {
        gap: 56px;
    }

    .orangePhone img {
        width: 129px;
        height: 206px;
    }
    .logoOrange {
        width: 40px;
        height: 40px;
    }
    
    .ecoFemale img {
        height: 200px;
        width: 200px;
    }

    .hockeyImg img, .llumar img {
        width: 210px;
        height: 161px;
    }
    .logoLlumar {
        width: 56px;
        height: 31px;
    }

    .logoHockey {
        width: 50px;
        height: 54px;
    }

    .blackGadjets img {
        width: 225px;
        height: 168px;
    }
    .logoGadjets {
        width: 78px;
        height: 17px;
    }
    .keysSitesBlock {
        padding-bottom: 55px;
    }
}